import React from "react";
import AdminLayout from "layouts/admin";
import { Switch, Route } from "react-router-dom";

function AdminRouter(props) {
  return (
    <AdminLayout>
      <Switch>
        <Route exact path={props.match.url + `/`}>
          <h2>Admin Dashboard</h2>
        </Route>
        <Route exact path={props.match.url + `/users`}>
          <h2>Users</h2>
        </Route>
      </Switch>
    </AdminLayout>
  );
}

export default AdminRouter;
