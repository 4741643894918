import React from "react";

export default function Loading(props) {
  if (props.error) {
    return <div>There was an error loading this page, please try again!</div>;
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return null;
  }
}
