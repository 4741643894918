import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer";
import history from "config/history";

// set the initial state
const initialState = {};

// get the compose function to run
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ latency: 0 })
  : compose;

// create the sage middle ware
const sagaMiddleware = createSagaMiddleware();

// create the store
const store = createStore(
  rootReducer(),
  initialState,
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

// add an object to keep track of the registered async reducers
store.asyncReducers = {};

// add an object to keep track of the registered async sagas
store.asyncSagas = {};

// create an inject reducer function
store.injectReducer = (key, asyncReducer) => {
  if (store.asyncReducers[key] === undefined) {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(rootReducer(store.asyncReducers));
  }
};

// create an inject saga function
store.injectSaga = (key, saga) => {
  if (store.asyncSagas[key] === undefined) {
    sagaMiddleware.run(saga);
  }
};

// hot module replacement
if (module.hot) {
  module.hot.accept("./reducer", () => {
    store.replaceReducer(rootReducer(store.asyncReducers));
  });
}

export default store;
