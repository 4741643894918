import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import AdminRouter from "pages/admin/router";
import ClientRouter from "pages/client/router";
import ClaimantRouter from "pages/claimant/router";

function MainRouter(props) {
  return (
    <Switch>
      <Route path="/admin" component={AdminRouter}></Route>
      <Route path="/client" component={ClientRouter}></Route>
      <Route path="/claimant" component={ClaimantRouter}></Route>
      <Route path="/">
        <h2 style={{ marginTop: 0 }}>Links</h2>
        <ul>
          <li>
            <Link to="/claimant">Claimant Dashboard</Link>
          </li>
          <li>
            <Link to="/admin">Admin Dashboard</Link>
          </li>
          <li>
            <Link to="/client">Client Dashboard</Link>
          </li>
          <li>
            <Link to="/claimant/create-claim">Create a claim</Link>
          </li>
        </ul>
      </Route>
    </Switch>
  );
}

export default MainRouter;
