import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import history from "config/history";
import store from "redux/store";
import { hot } from "react-hot-loader";
import Router from "./router";

export function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router />
      </ConnectedRouter>
    </Provider>
  );
}

export default hot(module)(App);
