import React from "react";
import ClientLayout from "layouts/client";
import { Switch, Route } from "react-router-dom";

function ClientRouter(props) {
  return (
    <ClientLayout>
      <Switch>
        <Route exact path={props.match.url + `/`}>
          <h2>Client Dashboard</h2>
        </Route>
        <Route exact path={props.match.url + `/staff`}>
          <h2>Staff</h2>
        </Route>
      </Switch>
    </ClientLayout>
  );
}

export default ClientRouter;
