import React from "react";
import { NavLink } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "styles/dashboard.scss";
import "fonts/lato/lato-regular-webfont.woff";
import "fonts/lato/lato-bold-webfont.woff";

import { Glyphicon, Dropdown, MenuItem } from "react-bootstrap";

import logo from "images/logo.jpg";

function AdminLayout(props) {
  return (
    <div className="body">
      <div className="sidebar">
        <img src={logo} width="250" alt="logo" />
        <hr />
        <ul className="nav">
          <li>
            <NavLink exact={true} to="/admin">
              <Glyphicon glyph="home"></Glyphicon>Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/users">
              <Glyphicon glyph="user"></Glyphicon>Users
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="main">
        <div className="navbar">
          <Dropdown id="admin-profile" style={{ float: "right" }} pullRight>
            <Dropdown.Toggle noCaret bsStyle="link">
              <Glyphicon glyph="user" style={{ cursor: "pointer" }}></Glyphicon>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <MenuItem>My Profile</MenuItem>
              <MenuItem>Logout</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
}

export default AdminLayout;
