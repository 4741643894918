import React from "react";
import ClaimantLayout from "layouts/claimant";
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "components/spinners/loading";

function ClaimantRouter(props) {
  return (
    <ClaimantLayout>
      <Switch>
        <Route
          path={props.match.url + `/claim/:claimId/edit`}
          component={Loadable({
            loader: () => import("pages/claimant/claim/edit"),
            loading: Loading,
          })}
        />
        <Route exact path={props.match.url + `/profile`}>
          <h2>Profile</h2>
        </Route>
        <Route
          exact
          path={props.match.url + `/create-claim`}
          component={Loadable({
            loader: () => import("pages/claimant/claim/create"),
            loading: Loading,
          })}
        />
        <Redirect
          from={props.match.url + "/"}
          to={props.match.url + "/claim/1/edit/contact-information"}
        />
      </Switch>
    </ClaimantLayout>
  );
}

export default ClaimantRouter;
